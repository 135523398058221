import React from "react";
import { graphql } from "gatsby";
import IndexPage from "./index-page";

export default (props) => <IndexPage {...props} />;

export const pageQuery = graphql`
  query IndexPageTemplatePL($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        frontImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        heading
        heading2
        heading3
        sports
        reverse
        subheading
        subheading2
        subheading3
        cta
        mainpitch {
          title
          description
          usps {
            usp
            description
            image {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          clientsheading
          clients {
            client {
              childImageSharp {
                fluid(maxWidth: 100, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          cta1
          cta2
        }
        presentation {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 800, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          feature1title
          feature1description
          feature2title
          feature2description
          feature3title
          feature3description
          feature3usp1
          feature3usp2
          feature3usp3
          demo_website
          demo_slideshow
          download_android
          download_ios
        }
        intro {
          blurbs {
            text
            author
            company
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          heading
          description
        }
        faqpricing {
          noanswer
          readfaq
          or
          contactus
          faq {
            question
            answer
          }
        }
        blog {
          heading
          subheading
          readmore
          cta
        }
      }
    }
    allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, draft: { ne: true } }
        fields: { langKey: { regex: "/(pl|any)/" } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    cases: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1
      filter: {
        frontmatter: {
          templateKey: { eq: "case-page-pl" }
          highlighted: { eq: true }
        }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            clientlogo {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            button_cta
            button_url
            highlighted
          }
        }
      }
    }
    pricing: markdownRemark(
      frontmatter: { templateKey: { eq: "pricing" }, languageKey: { eq: "pl" } }
    ) {
      frontmatter {
        pricing {
          heading
          description
          tournament
          online
          qta
          url
          pertournament
          forever
          free
          online_tooltip
          online_description
          amateur {
            name
            feature1
            feature2
            feature3
          }
          worldclass {
            name
            feature1
            feature2
            feature3
            feature4
          }
          legendary {
            name
            feature1
            feature2
            feature3
            feature4
          }
          onlinefree {
            name
            feature1
            feature2
            feature3
          }
          pro {
            name
            feature1
            feature2
            feature3
          }
          subscription {
            name
            description1
            description2
            request_brochure
            email
            request
            request_thanks
            request_failed
          }
        }
      }
    }
  }
`;
